<app-dialog-wrapper>
  <div class="header">
    <span>{{editMode ? 'Upraviť kategorizáciu' : 'Pridať kategorizáciu'}}</span>
    <mat-icon (click)="close()">close</mat-icon>
  </div>
  <div class="horizontal-divider"></div>
  <div  *ngIf="!editMode" class="hint">
    <mat-icon>info</mat-icon>
      <span>
    Existujúcu kategorizáciu môžete skopírovať z náhľadu kategorizácie.
    Následne je možné upraviť skopírovanú kategorizáciu podľa potreby
  </span>
  </div>
  <form [formGroup]="fg">
    <div class="form-grid">
      <div *ngFor="let field of fields" [ngClass]="{'date-row': field.type === 'date', 'full-width': field.type === 'date'}" class="form-field">
        <mat-form-field appearance="outline" class="form-field-item">
          <mat-label>{{ field.label }}</mat-label>
          <ng-container [ngSwitch]="field.type">
            <mat-select *ngSwitchCase="'select'" [formControlName]="field.formControlName">
              <mat-option *ngFor="let option of (field.options$ | async)" [value]="option.value">
                {{ option.label }}
              </mat-option>
            </mat-select>
            <input matInput *ngSwitchCase="'text'" [formControlName]="field.formControlName" />
            <ng-container *ngSwitchCase="'date'">
              <div class="row-date">
                <input
                  matInput
                  [matDatepicker]="picker"
                  [formControlName]="field.formControlName"
                />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </div>
            </ng-container>
          </ng-container>

          <mat-error *ngIf="fg.get(field.formControlName)?.invalid && fg.get(field.formControlName)?.touched">
            <ng-container *ngIf="fg.get(field.formControlName)?.hasError('required')">
              {{ field.errorMessage }}
            </ng-container>

            <ng-container *ngIf="field.formControlName === 'number' && fg.get('number')?.hasError('nameExists')">
              Názov kategorizácie už existuje. Zadajte iný názov.
            </ng-container>

            <ng-container *ngIf="field.formControlName === 'validSince' || field.formControlName === 'validUntil'">
              <ng-container *ngIf="fg.get('validSince')?.hasError('invalidRange')">
                Dátum od nemôže byť skôr ako dátum do.
              </ng-container>

              <ng-container *ngIf="fg.get('validSince')?.hasError('overlap')">
                Vybrané dátumy sa prekrývajú s existujúcou kategorizáciou:
                <ng-container *ngIf="fg.get('validSince')?.getError('conflictingCategorization') as conflict">
                  <b>{{ conflict.categorizationName }}</b>
                  s intervalom
                  {{ conflict.validSince | date: 'shortDate' }}
                  <ng-container *ngIf="conflict.validUntil; else infinityMessage">
                    do {{ conflict.validUntil | date: 'shortDate' }}.
                  </ng-container>
                  <ng-template #infinityMessage>
                   do nekonečna. Žiadny voľný interval nie je k dispozícii, pretože táto kategorizácia má nekonečné časové rozmedzie.
                  </ng-template>
                </ng-container>
                <br />
                <ng-container *ngIf="fg.get('validSince')?.getError('nextFreeStartDate') as nextFreeStart">
                  Najbližší dostupný interval je od
                  <ng-container *ngIf="nextFreeStart; else noFreeInterval">
                    {{ nextFreeStart | date: 'shortDate' }}
                  </ng-container>
                  <ng-template #noFreeInterval>
                    <b>není</b>.
                  </ng-template>
                </ng-container>
              </ng-container>
            </ng-container>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
  <div
    class="buttons-group"
    [ngClass]="{'expanded-margin':
     fg.get('validSince')?.getError('conflictingCategorization')?.validUntil === null}">
    <button
      class="button"
      (click)="save()">
      {{ editMode ? 'Upraviť kategorizáciu' : 'Pridať kategorizáciu' }}
    </button>
    <button
      class="button"
      (click)="close()">
      Zrušiť
    </button>
  </div>
</app-dialog-wrapper>
