import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  OccupationCapabilityUpdateApiModel,
  OccupationCategorizationService,
} from 'src/api-new';

@Component({
  selector: 'add-factor-activity-selection-modal',
  templateUrl: './add-factor-activity-modal.component.html',
  styleUrls: ['./add-factor-activity-modal.component.scss']
})
export class AddFactorActivityModalComponent implements OnInit, OnDestroy {
  protected _data: any[];
  occupationId: number;
  employerId: number;
  selectedIds: number[];

  categorizationId: number;
  loading = true;
  allRowsSelected = false;
  someRowsSelected = false;
  showAll = true;
  queryName: string = '';
  queryDtype: string = 'RiskFactor';
  gridFilterItems = {};

  constructor(
    private ref: NbDialogRef<AddFactorActivityModalComponent>,
    private activatedRoute: ActivatedRoute,
    private categorizationService: OccupationCategorizationService,
    private nbToastr: NbToastrService
  ) {
  }

  ngOnInit(): void {
    this.categorizationId = parseInt(this.activatedRoute.snapshot.queryParamMap.get('categorizationId') ?? '');
    forkJoin([
      this.categorizationService.occupationCategorizationGetOccupationDetails(this.categorizationId,this.occupationId),
    ]).subscribe(([occupation]) => {
      this._data = [
        ...occupation.workActivities,
        ...occupation.riskFactors
      ].map(item => ({
        id: item.id,
        isSelected: this.selectedIds.find(x => x == item.id) ? true : false,
        expand: false,
        dtype: item.type,
        name: item.name,
        specification: item.specification,
        code: item.code,
        validity: item.eligibleIntervals.map(x => x?.eligibleMonthInterval).join(','),
        oldSelected: this.selectedIds.find(x => x == item.id) ? true : false,
      }))
      this._data.forEach(row => {
        if (row.isSelected) {
          this._data.forEach(e => {
            if (row.code == e.code) {
              if (row.id != e.id)
                e.isDisabled = true;
            }
          })
        }
      });

      this.loading = false;
      document.getElementById('searchName')?.focus();
    });
  }

  get data(): any[] {
    if (this._data) {
      if (this.showAll)
        return this._data
          .filter(x => x.dtype === this.queryDtype)
          .filter(x => x.name.toLowerCase().includes(this.queryName.toLowerCase()) || x.code.toLowerCase().includes(this.queryName.toLowerCase()));
      else
        return this._data
          .filter(x => x.isSelected === true)

    } else {
      return []
    }
  }

  calcRowHeight(row: any): number {
    const calculateLines = (text: string, containerWidth = 150, fontSize = 10) => {
      let lines = 1;
      const widths = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.278125, 0.278125, 0.35625, 0.55625, 0.55625, 0.890625, 0.6671875, 0.1921875, 0.334375, 0.334375, 0.390625, 0.584375, 0.278125, 0.334375, 0.278125, 0.303125, 0.55625, 0.55625, 0.55625, 0.55625, 0.55625, 0.55625, 0.55625, 0.55625, 0.55625, 0.55625, 0.278125, 0.278125, 0.5859375, 0.584375, 0.5859375, 0.55625, 1.015625, 0.6671875, 0.6671875, 0.7234375, 0.7234375, 0.6671875, 0.6109375, 0.778125, 0.7234375, 0.278125, 0.5, 0.6671875, 0.55625, 0.834375, 0.7234375, 0.778125, 0.6671875, 0.778125, 0.7234375, 0.6671875, 0.6109375, 0.7234375, 0.6671875, 0.9453125, 0.6671875, 0.6671875, 0.6109375, 0.278125, 0.35625, 0.278125, 0.478125, 0.55625, 0.334375, 0.55625, 0.55625, 0.5, 0.55625, 0.55625, 0.278125, 0.55625, 0.55625, 0.2234375, 0.2421875, 0.5, 0.2234375, 0.834375, 0.55625, 0.55625, 0.55625, 0.55625, 0.334375, 0.5, 0.278125, 0.55625, 0.5, 0.7234375, 0.5, 0.5, 0.5, 0.35625, 0.2609375, 0.3546875, 0.590625]
      const avg = 0.5293256578947368

      text.split('')
        .map(c => c.charCodeAt(0) < widths.length ? widths[c.charCodeAt(0)] : avg)
        .reduce((cur, acc) => {
          if ((acc + cur) * fontSize > containerWidth) {
            lines++;
            cur = acc;
          }
          return acc + cur;
        });

      return lines;
    }
    const hdrSize = document.getElementById('hdrMeno')?.parentElement?.offsetWidth;
    if (hdrSize) {
      return Math.max(34, calculateLines(row.specification, hdrSize) * 20)
    } else {
      return 34;
    }
  }

  getCountSelectedRows() {
    return this._data
      .filter(x => x.isSelected === true).length;
  }

  cancel() {
    this.ref.close(false);
  }

  ngOnDestroy() {
  }

  clearSearch() {
    this.queryName = '';
    document.getElementById('searchName')?.focus();
  }

  toggleAllRows(checked: boolean) {
    this.allRowsSelected = checked;
    this.someRowsSelected = false;
    this.data.forEach(e => {
      e.isSelected = checked;
      e.isDisabled = false;
    });
  }

  toggleRow(checked: boolean, row: any) {
    row.isSelected = checked;
    if (checked) {
      this.data.forEach(e => {
        if (row.code == e.code) {
          if (row.id == e.id)
            e.isSelected = checked;
          else
            e.isDisabled = checked;
        }
      });
    } else {
      this.data.forEach(e => {
        if (row.code == e.code) {
          e.isSelected = checked;
          e.isDisabled = checked;
        }
      });
    }
  }

  getRowClass() {
    return 'clickable';
  }

  save() {
    this.loading = true;

    const dto: OccupationCapabilityUpdateApiModel = {
      capabilityIds: this._data
        .filter(item => item.isSelected)
        .map(item => item.id),
      categorizationId: this.categorizationId,
      occupationId: this.occupationId,
    };
    this.categorizationService.occupationCategorizationAssignCapabilityToOccupation(dto)
      .subscribe(() => {
          this.nbToastr.success('Faktory boli úspešne pridané', null, {duration: 5000});
          this.loading = false;
          this.ref.close(true);
        },
        catchError((err) => {
          this.nbToastr.danger('Chyba pri pridávaní faktorov.', null, {duration: 5000});
          return of(err);
      }));
  }
}
