import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {NbDialogRef, NbToastrService} from "@nebular/theme";
import {
  OccupationCategorizationApiModel,
  OccupationCategorizationCopyApiModel,
  OccupationCategorizationService
} from "../../../../../api-new";
import {TableLoaderService} from "../../../../services/table-loader.service";
import {calculateNextFreeDate, calculateNextFreeDateWithoutEndDate} from "../../../../utils";

@Component({
  selector: 'app-create-copy-categerization-dialog',
  templateUrl: './create-copy-categerization-dialog.component.html',
  styleUrls: ['./create-copy-categerization-dialog.component.scss']
})
export class CreateCopyCategerizationDialogComponent implements OnInit, OnDestroy {
  fg: FormGroup;
  fields: any[];
  categorization: OccupationCategorizationApiModel;
  subs: Subscription = new Subscription();
  employerName: string;
  existingCategorizations: OccupationCategorizationApiModel[] = [];

  constructor(
    private fb: FormBuilder,
    private ref: NbDialogRef<CreateCopyCategerizationDialogComponent>,
    private occupationCategorizationService: OccupationCategorizationService,
    private nbToastr: NbToastrService,
    private tableLoader: TableLoaderService
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.setupFields();
    this.loadExistingCategorizations();
  }

  private initializeForm(): void {
    this.fg = this.fb.group({
      employerName: [{ value: this.employerName, disabled: true }, Validators.required],
      number: ['', Validators.required],
      validSince: [null, Validators.required],
      validUntil: [null],
    });

    this.fg.get('number')?.valueChanges.subscribe((name) => this.validateName(name));
    this.fg.get('validSince')?.valueChanges.subscribe(() => this.validateDateRange());
    this.fg.get('validUntil')?.valueChanges.subscribe(() => this.validateDateRange());
  }

  private setupFields(): void {
    this.fields = [
      {
        formControlName: 'employerName',
        label: 'Zamestnávateľ (predvybraté)',
        type: 'text',
        errorMessage: 'Vyžaduje sa zamestnávateľ.',
      },
      {
        formControlName: 'number',
        label: 'Číslo kategorizácie',
        type: 'text',
        validators: [Validators.required],
        errorMessage: 'Číslo kategorizácie je povinné.',
      },
      {
        formControlName: 'validSince',
        label: 'Platná od',
        type: 'date',
        validators: [Validators.required],
        errorMessage: 'Platná od je povinná.',
      },
      {
        formControlName: 'validUntil',
        label: 'Platná do',
        type: 'date',
        validators: [Validators.required],
        errorMessage: 'Platná do je povinná.',
      },
    ];
  }

  private loadExistingCategorizations(): void {
    this.subs.add(
      this.occupationCategorizationService.occupationCategorizationGetAll().subscribe(
        (data) => {
          this.existingCategorizations = data;
        },
        (err) => {
          this.nbToastr.danger('Nepodarilo sa načítať existujúce kategorizácie.', 'Chyba');
        }
      )
    );
  }

  private validateName(name: string): void {
    if (!name) {
      return;
    }

    const nameExists = this.existingCategorizations.some(
      (category) => category.categorizationName.toLowerCase() === name.toLowerCase()
    );

    if (nameExists) {
      this.fg.get('number')?.setErrors({ nameExists: true });
    } else {
    }
  }

  private validateDateRange(): void {
    const validSince = this.fg.get('validSince')?.value;
    const validUntil = this.fg.get('validUntil')?.value;


    this.fg.get('validUntil')?.setErrors(null);

    if (!validSince) return;

    const startDate = new Date(validSince);
    const endDate = validUntil ? new Date(validUntil) : null;

    if (endDate && startDate > endDate) {
      this.fg.get('validSince')?.setErrors({ invalidRange: true });
      this.fg.get('validUntil')?.setErrors({ invalidRange: true });
      return;
    }

    const sortedCategorizations = [...this.existingCategorizations].sort((a, b) =>
      new Date(a.validSince).getTime() - new Date(b.validSince).getTime()
    );

    let overlap = false;
    let conflictingCategorization: OccupationCategorizationApiModel | null = null;
    let nextFreeStartDate: Date | null = null;

    let infiniteCategorization = sortedCategorizations.find(item => item.validUntil === null);

    const infinityOccupationStartDate = infiniteCategorization
      ? new Date(infiniteCategorization.validSince)
      : null;

    for (let i = 0; i < sortedCategorizations.length; i++) {
      const categorization = sortedCategorizations[i];

      const catStart = new Date(categorization.validSince);
      const catEnd = categorization.validUntil ? new Date(categorization.validUntil) : null;

      if (!endDate && infiniteCategorization && infinityOccupationStartDate) {
        overlap = true;
        conflictingCategorization = infiniteCategorization;
        break;
      }

      if (!endDate && catEnd && startDate <= catEnd) {
        nextFreeStartDate = endDate == null ? null : calculateNextFreeDateWithoutEndDate(startDate, sortedCategorizations);
        overlap = true;
        conflictingCategorization = categorization;
        break;
      }

      if (endDate) {
        if (infiniteCategorization && infinityOccupationStartDate && infinityOccupationStartDate <= startDate) {
          overlap = true;
          conflictingCategorization = infiniteCategorization;
          nextFreeStartDate = null;
          break;
        }

        if (catEnd && startDate <= catEnd && endDate >= catStart) {
          overlap = true;
          conflictingCategorization = categorization;
          if (catEnd) {
            nextFreeStartDate = calculateNextFreeDate(catEnd, sortedCategorizations, i);
          }
          break;
        }

        if (infiniteCategorization && infinityOccupationStartDate && endDate >= infinityOccupationStartDate) {
          overlap = true;
          conflictingCategorization = infiniteCategorization;
          nextFreeStartDate = null;
          break;
        }
      }
    }

    if (overlap) {

      const sinceDate = new Date(conflictingCategorization!.validSince).toLocaleDateString();
      const untilPart = conflictingCategorization?.validUntil
        ? `do ${new Date(conflictingCategorization.validUntil).toLocaleDateString()}`
        : 'do nekonečna';

      const conflictMessage = `Vybrané dátumy sa prekrývajú s existujúcou kategorizáciou: ${
        conflictingCategorization?.categorizationName
      } s intervalom ${sinceDate} ${untilPart}.`;


      this.fg.get('validSince')?.setErrors({
        overlap: true,
        conflictingCategorization: {
          categorizationName: conflictingCategorization?.categorizationName,
          validSince: conflictingCategorization?.validSince,
          validUntil: conflictingCategorization?.validUntil
        },
        nextFreeStartDate,
        conflictMessage,
      });
      if(validSince) {
        this.fg.get('validUntil')?.setErrors({
          overlap: true,
          conflictingCategorization: {
            categorizationName: conflictingCategorization?.categorizationName,
            validSince: conflictingCategorization?.validSince,
            validUntil: conflictingCategorization?.validUntil,
          },
          nextFreeStartDate,
          conflictMessage,
        });
      }

    } else {

      this.fg.get('validSince')?.setErrors(null);
      this.fg.get('validUntil')?.setErrors(null);
    }
  }

  save(): void {

    this.validateDateRange();

    if (this.fg.invalid) {
      this.fg.markAllAsTouched();
      return;
    }

    this.tableLoader.set(true);

    const formValue = this.fg.getRawValue();
    const normalizeToUTC = (date: Date | null): string | null => {
      if (!date) return null;
      const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      return utcDate.toISOString();
    };
    const dto: OccupationCategorizationCopyApiModel = {
      categorizationIdToCopy: this.categorization.id,
      newName: formValue.number,
      validSince: normalizeToUTC(formValue.validSince!)!,
      validUntil: normalizeToUTC(formValue.validUntil!)!,
    };

    this.subs.add(
      this.occupationCategorizationService.occupationCategorizationCopyCategorization(dto).subscribe(
        () => this.ref.close(true),
        (err) => {
          this.tableLoader.set(false);
          this.nbToastr.danger('Chyba v komunikácii so serverom', err.status);
        }
      )
    );
  }

  close(): void {
    this.ref.close(false);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
