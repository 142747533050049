import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import {
  OccupationApiModel,
  OccupationCreateApiModel,
  OccupationService,
  OccupationUpdateApiModel
} from 'src/api-new';
import { TableLoaderService } from 'src/app/services/table-loader.service';

@Component({
  selector: 'app-add-edit-occupation-modal',
  templateUrl: './add-edit-occupation-modal.component.html',
  styleUrls: ['./add-edit-occupation-modal.component.scss']
})
export class AddEditOccupationModalComponent implements OnInit {
  currentEmployerId: number;
  @Input() occupation: OccupationApiModel | null = null;
  @Input() fromCategorization: boolean = false;

  editMode = false;
  loadingOccupation = false;

  fg: FormGroup | null = null;

  constructor(
    private tableLoader: TableLoaderService,
    private ref: NbDialogRef<AddEditOccupationModalComponent>,
    private occupationService: OccupationService,
    private nbToastr: NbToastrService
  ) {}

  ngOnInit(): void {
    if (this.occupation) {
      this.editMode = true;
    }
  }

  onFormChange(form: FormGroup): void {
    this.fg = form;
  }

  close(): void {
    this.ref.close(false);
  }

  save(): void {
    if (!this.fg || this.fg.invalid) {
      this.fg?.markAllAsTouched();
      return;
    }

    this.tableLoader.set(true);

    if (this.editMode) {
      const updateDto: OccupationUpdateApiModel = {
        id: this.occupation?.id!,
        name: this.fg.value.name,
        valid: this.fg.value.valid,
        allRiskFactorExam: this.fg.value.completePeriodicCheck,
      };
      this.occupationService.occupationUpdate(updateDto).subscribe(
        () => this.ref.close(true),
        (error) => {
          this.tableLoader.set(false);
          this.nbToastr.danger(
            error.error.error ?? 'Nastala chyba v komunikácii so serverom.',
            'Chyba',
            { duration: 5000 }
          );
        }
      );
    } else {
      const createDto: OccupationCreateApiModel = {
        employerId: this.currentEmployerId,
        name: this.fg.value.name,
        valid: this.fg.value.valid,
        allRiskFactorExam: this.fg.value.completePeriodicCheck,
      };
      this.occupationService.occupationCreate(createDto).subscribe(
        () => {
          this.ref.close(true);
        },
        (err) => {
          this.tableLoader.set(false);
          this.nbToastr.danger(
            'Chyba v komunikácii so serverom',
            err.status,
            { duration: 5000 }
          );
        }
      );
    }
  }
}
