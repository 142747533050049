<app-dialog-wrapper [loading]="loadingOccupation">
  <div class="header">
    <span>{{editMode ? 'Upraviť profesiu' : 'Pridať profesiu'}}</span>
    <mat-icon (click)="close()">close</mat-icon>
  </div>
  <div class="horizontal-divider"></div>

  <app-form-occupation
    [editMode]="editMode"
    [occupation]="occupation"
    (formChange)="onFormChange($event)">
  </app-form-occupation>

  <div class="buttons-group">
    <button
      class="button"
      [disabled]="fg?.invalid"
      (click)="save()">
      {{ editMode ? 'Upraviť profesiu' : 'Pridať profesiu' }}
    </button>
    <button
      class="button"
      (click)="close()">
      Zrušiť
    </button>
  </div>
</app-dialog-wrapper>
