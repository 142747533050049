import { Directive, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
    selector: '[appTooltip]',
    exportAs: 'appTooltip',
})
export class SharedUiTooltipDirective extends MatTooltip {

    @Input() appTooltip!: string

    override get message(): string {
        return this.appTooltip
    }

    @Input() isDisabled?: boolean

    override get disabled(): boolean {
        return this.isDisabled ?? false
    }
}