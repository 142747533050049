import { Component } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-back-to-settings-button',
  templateUrl: './back-to-settings-button.component.html',
  styleUrls: ['./back-to-settings-button.component.scss']
})
export class BackToSettingsButtonComponent {

  constructor(private router: Router) { }

  goBackToSettings():void {
    this.router.navigate(['nastavenia']).then(()=>true);
  }


}
