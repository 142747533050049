import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import {Subject, Observable, forkJoin, of} from 'rxjs';
import {catchError, finalize, first, switchMap, tap} from 'rxjs/operators';
import {
  EmployerApiModel,
  EmployerService,
  Occupation,
  OccupationApiModel, OccupationAssessiblesCountApiModel, OccupationCategorizationApiModel,
  OccupationCategorizationService,
  OccupationService
} from 'src/api-new';
import { TableLoaderService } from 'src/app/services/table-loader.service';
import { UserDetailService } from 'src/app/services/user-detail.service';
import { AddEditOccupationModalComponent } from '../add-edit-occupation-modal/add-edit-occupation-modal.component';
import { Sort } from "../../../../../utils";
import { UserRole } from "../../../../../services/auth.service";
import { MatDialog } from "@angular/material/dialog";
import {OccupationFilterDialogComponent} from "../occupation-filter-dialog/occupation-filter-dialog.component";
import {
  AddEditCategorizationModalComponent
} from "../../../../categorizations/components/add-edit-categorization-modal/add-edit-categorization-modal.component";
import {
  OccupationCategorizationDialogComponent
} from "../occupation-categorization-dialog/occupation-categorization-dialog.component";
import {
  CreateCopyCategerizationDialogComponent
} from "../../../../categorizations/components/create-copy-categerization-dialog/create-copy-categerization-dialog.component";

export interface OccupationApiModelExtra extends OccupationApiModel{
  numberOfRiskFactor:number;
  numberOfWorkActivity:number;
  containsHighRiskFactor: boolean;
}

@Component({
  selector: 'app-occupations-data-grid',
  templateUrl: './occupations-data-grid.component.html',
  styleUrls: ['./occupations-data-grid.component.scss']
})
export class OccupationsDataGridComponent implements OnInit {

  @Input() fromCategorization?: boolean = false;
  refreshData = new Subject<void>();
  loading$: Observable<boolean>;
  data: Array<OccupationApiModelExtra> = [];
  userRole = this.userDetailService.role;
  categorizationId: number;
  currentEmployer: EmployerApiModel;
  currentEmployerId: number;
  numberOfOccupation: number;
  nameSearch: string | undefined = '';
  filterValidOccupation: boolean = true;
  filterInactiveOccupation: boolean = false;
  sorts: Sort[] = [{ prop: 'name', dir: 'asc' }];
  activeOccupationCategorization: OccupationCategorizationApiModel;
  currentOccupationCategorization: OccupationCategorizationApiModel;

  constructor(
    private userDetailService: UserDetailService,
    private activatedRoute: ActivatedRoute,
    private categorizationService: OccupationCategorizationService,
    private tableLoader: TableLoaderService,
    private employerService: EmployerService,
    private toaster: NbToastrService,
    private occupationService: OccupationService,
    private nbDialog: NbDialogService,
    private nbToastr: NbToastrService,
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.initializeGrid();
  }

  initializeGrid(): void {
    this.loading$ = this.tableLoader.loading$;
    this.tableLoader.set(true);

    this.refreshData.pipe(
      switchMap(() => this.loadOccupationsByRole()),
      catchError((err) => {
        this.handleError(err);
        return [];
      })
    ).subscribe(res => {});
    this.refreshData.next();
  }

  private loadOccupationsByRole(): Observable<any> {
    if (this.userRole === UserRole.pzs_manager) {
      return this.loadManagerOccupations();
    } else {
      return this.loadCurrentEmployerOccupations();
    }
  }


  private loadManagerOccupations(): Observable<any> {
    this.tableLoader.set(true);

    return this.activatedRoute.queryParams.pipe(
      first(),
      switchMap((params) =>
        this.categorizationService.occupationCategorizationGet(params.categorizationId).pipe(
          tap((categorization) => {
            this.categorizationId = categorization.id;
            this.currentEmployerId = categorization.employerId;
            this.currentOccupationCategorization = categorization;
          })
        )
      ),
      switchMap(() =>
        this.categorizationService.occupationCategorizationGetActiveCat(this.currentEmployerId).pipe(
          tap((activeCategorization) => {
            this.activeOccupationCategorization = activeCategorization;
          })
        )
      ),
      switchMap(() =>
        this.employerService.employerGetEmployer(this.currentEmployerId).pipe(
          tap((employer) => {
            this.currentEmployer = employer;
          })
        )
      ),
      switchMap(() =>
        forkJoin({
          filteredOccupations: this.loadFilteredOccupations(this.currentEmployerId),
          occupationCounts: this.categorizationService.occupationCategorizationGetOccupationListItems(this.categorizationId)
            .pipe(catchError(() => of([] as OccupationAssessiblesCountApiModel[]))),
        })
      ),
      tap(({ filteredOccupations, occupationCounts }) => {
        const countsMap = occupationCounts.reduce((map, item) => {
          map[item.occupationId] = item;
          return map;
        }, {} as { [key: number]: OccupationAssessiblesCountApiModel });

        const mergedData = filteredOccupations.map(occupation => {
          const countData = countsMap[occupation.id] || {};
          return {
            ...occupation,
            numberOfRiskFactor: countData.riskFactorCount ?? 0,
            numberOfWorkActivity: countData.workActivityCount ?? 0,
            containsHighRiskFactor: countData.containsHighRiskFactor ?? false
          };
        });

        this.data = mergedData.filter(occupation => {
          if (this.filterValidOccupation && this.filterInactiveOccupation) return true;
          if (!this.filterValidOccupation && !this.filterInactiveOccupation) return false;
          return this.filterValidOccupation ? occupation.valid : !occupation.valid;
        });
        this.numberOfOccupation = this.data.length;
        this.tableLoader.set(false);
      }),
      finalize(() => {
        this.tableLoader.set(false);
      }),
      catchError((err) => {
        this.handleError(err);
        return of([]);
      })
    );
  }

  private loadCurrentEmployerOccupations(): Observable<any> {
    if(!this.fromCategorization) {
        return this.employerService.employerGetCurrentEmployer().pipe(
          tap((currentEmployer) => {
            this.currentEmployerId = currentEmployer.id;
            this.currentEmployer = currentEmployer;
            this.categorizationService.occupationCategorizationGetActiveCat(currentEmployer.id)
              .toPromise()
              .then(cat => {
                this.categorizationId = cat.id;
                this.activeOccupationCategorization = cat;
                this.loadLengthForActivitiesAndRiskItems(this.categorizationId);
              });
          }),
          switchMap(() => this.loadFilteredOccupations(this.currentEmployerId))
        );
    } else {
      return this.activatedRoute.queryParams.pipe(
        first(),
        switchMap((params) =>
          this.categorizationService.occupationCategorizationGet(params.categorizationId).pipe(
            tap((categorization) => {
              this.categorizationId = categorization.id;
              this.currentOccupationCategorization = categorization;
            })
          )
        ),
        switchMap(()=>this.employerService.employerGetCurrentEmployer().pipe(
          tap((currentEmployer) => {
            this.currentEmployerId = currentEmployer.id;
            this.currentEmployer = currentEmployer;
          }))),
        switchMap(() =>
          this.categorizationService.occupationCategorizationGetActiveCat(this.currentEmployerId).pipe(
            tap((activeCat) => {
              this.activeOccupationCategorization = activeCat;
            })
          )
        ),
        switchMap(() =>
          forkJoin({
            filteredOccupations: this.loadFilteredOccupations(this.currentEmployerId),
            occupationCounts: this.categorizationService.occupationCategorizationGetOccupationListItems(this.categorizationId)
              .pipe(catchError(() => of([] as OccupationAssessiblesCountApiModel[])))
          })
        ),
        tap(({ filteredOccupations, occupationCounts }) => {
          const countsMap: { [key: number]: OccupationAssessiblesCountApiModel } = occupationCounts.reduce((map, item) => {
            map[item.occupationId] = item;
            return map;
          }, {} as { [key: number]: OccupationAssessiblesCountApiModel });

          const mergedData = filteredOccupations.map(occupation => {
            const countData = countsMap[occupation.id] || {};
            return {
              ...occupation,
              numberOfRiskFactor: countData.riskFactorCount ?? 0,
              numberOfWorkActivity: countData.workActivityCount ?? 0
            };
          });

          this.data = mergedData.filter(occupation => {
            if (this.filterValidOccupation && this.filterInactiveOccupation) return true;
            if (!this.filterValidOccupation && !this.filterInactiveOccupation) return false;
            return this.filterValidOccupation ? occupation.valid : !occupation.valid;
          });
          this.numberOfOccupation = this.data.length;
          this.tableLoader.set(false);
        })
      );
    }
  }

  private loadFilteredOccupations(employerId: number): Observable<any> {
    return this.occupationService.occupationGetAll(employerId, this.nameSearch).pipe(
      tap((data) => {
        let filteredData = data;
        if (this.filterValidOccupation && this.filterInactiveOccupation) {
          filteredData = data;
        }
        else if (!this.filterValidOccupation && !this.filterInactiveOccupation) {
          filteredData = [];
        }
        else if (this.filterValidOccupation) {
          filteredData = filteredData.filter(occupation => occupation.valid);
        }
        else if (this.filterInactiveOccupation) {
          filteredData = filteredData.filter(occupation => !occupation.valid);
        }
        this.numberOfOccupation = filteredData.length;
        this.tableLoader.set(false);
        this.data = filteredData;
      })
    );
  }

  private handleError(err: any): void {
    this.tableLoader.set(false);
    this.nbToastr.danger('Chyba v komunikácii so serverom', err.error?.status, { duration: 5000 });
  }

  addOccupation(): void {
      this.nbDialog.open(AddEditOccupationModalComponent, {
        context: { currentEmployerId: this.currentEmployerId }
      }).onClose.subscribe((res) => {
        if (res) {
          this.refreshData.next();
        }
      });
  }

  editCategorization(): void {
    this.nbDialog.open(AddEditCategorizationModalComponent, {
      context: { categorization: this.activeOccupationCategorization?.id === this.currentOccupationCategorization?.id ? this.activeOccupationCategorization : this.currentOccupationCategorization }
    }).onClose.subscribe((res) => {
      if (res) {
        this.activeOccupationCategorization = res;
        this.refreshData.next();
      }
    });
  }

  copyCategorization(): void {
    this.nbDialog.open(CreateCopyCategerizationDialogComponent, {
      context: {
        categorization: this.activeOccupationCategorization?.id === this.currentOccupationCategorization?.id ? this.activeOccupationCategorization : this.currentOccupationCategorization,
        employerName: this.currentEmployer.name
      }
    }).onClose.subscribe((res) => {
      if (res) {
        this.activeOccupationCategorization = res;
        this.refreshData.next();
      }
    });
  }

  editOccupation(row: OccupationApiModel): void {
    if(this.fromCategorization) {
      this.nbDialog.open(OccupationCategorizationDialogComponent, {
        context: {
          currentEmployerId: this.currentEmployerId,
          occupation: row,
          categorization: this.currentOccupationCategorization
        }
      }).onClose.subscribe((res) => {
        if (res) {
          this.refreshData.next();
        }
      });
    } else {
      this.nbDialog.open(AddEditOccupationModalComponent, {
        context: { currentEmployerId: this.currentEmployerId, occupation: row }
      }).onClose.subscribe((res) => {
        if (res) {
          this.refreshData.next();
        }
      });
    }
  }

  onRowActivate(event: any): void {
    if (event.type === 'click') {
      const targetElement = event.event.target;
      if (targetElement.closest('.row') === null) {
        const row: OccupationApiModel = event.row;
        this.editOccupation(row);
      }
    }
  }

  getRowClass(row: Occupation): string {
    return row ? 'clickable' : 'not-clickable';
  }


  searchByName(event: { employerName: string }): void {
    const search = event.employerName.trim();
    this.nameSearch = search === '' ? undefined : search;
    this.tableLoader.set(true);
    this.refreshData.next();
  }

  openFilter(): void {
    const dialogRef = this.dialog.open(OccupationFilterDialogComponent, {
      disableClose: true,
      data: {
        filterValidOccupation: this.filterValidOccupation,
        filterInactiveOccupation: this.filterInactiveOccupation,
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.filterValidOccupation = result.filterValidOccupation;
        this.filterInactiveOccupation = result.filterInactiveOccupation;
        this.refreshData.next();
      }
    });
  }

  getNumFilters(): string {
    let numFilters = 0;
    numFilters += this.filterValidOccupation ? 1 : 0;
    numFilters += this.filterInactiveOccupation ? 1 : 0;
    return numFilters.toString();
  }

  onCustomCellClick(): void {
    const categorizationId = this.categorizationId;
    this.router.navigate(
      [`nastavenia/kategorizacie/kategorizacia/${categorizationId}`],
      { queryParams: { categorizationId } }).then(()=> true);
  }

  onSort(event: any): void {
    const { prop, dir } = event.sorts[0];

    if (prop === 'riskAndWorkActivities') {
      this.data = [...this.data].sort((a, b) => this.sortByRiskAndWorkActivities(a, b, dir));
    } else {
      this.data = [...this.data].sort((a, b) => {
        if (a[prop] < b[prop]) return dir === 'asc' ? -1 : 1;
        if (a[prop] > b[prop]) return dir === 'asc' ? 1 : -1;
        return 0;
      });
    }
  }

  private sortByRiskAndWorkActivities(a: OccupationApiModelExtra, b: OccupationApiModelExtra, dir: 'asc' | 'desc'): number {
    const multiplier = dir === 'asc' ? 1 : -1;
    if (a.numberOfRiskFactor !== b.numberOfRiskFactor) {
      return (a.numberOfRiskFactor - b.numberOfRiskFactor) * multiplier;
    }
    return (a.numberOfWorkActivity - b.numberOfWorkActivity) * multiplier;
  }

  loadLengthForActivitiesAndRiskItems(categorizationId: number): void {
    this.categorizationService.occupationCategorizationGetOccupationListItems(categorizationId).subscribe(
      (data: OccupationAssessiblesCountApiModel[]) => {
        const countsMap = data.reduce((map, item) => {
          map[item.occupationId] = item;
          return map;
        }, {} as { [key: number]: OccupationAssessiblesCountApiModel });
        this.data = this.data.map(occupation => {
          const countData = countsMap[occupation.id] || {};
          return {
            ...occupation,
            numberOfRiskFactor: countData.riskFactorCount ?? 0,
            numberOfWorkActivity: countData.workActivityCount ?? 0,
            containsHighRiskFactor: countData.containsHighRiskFactor ?? false
          };
        });
      }
    );
  }

}

