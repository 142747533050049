import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OccupationApiModel, OccupationService, OccupationUpdateApiModel} from "../../../../../../api-new";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-form-occupation',
  templateUrl: './form-occupation.component.html',
  styleUrls: ['./form-occupation.component.scss']
})
export class FormOccupationComponent implements OnInit {
  @Input() editMode: boolean = false;
  @Input() occupation: OccupationApiModel | null = null;

  @Output() formChange = new EventEmitter<FormGroup>();

  fg: FormGroup;

  constructor(
    private fb: FormBuilder,
    private occupationService: OccupationService,
  ) {}

  ngOnInit(): void {
    this.initForm();
    if (this.occupation) {
      this.populateForm();
    }

    this.fg.valueChanges.subscribe(() => {
      this.formChange.emit(this.fg);
    });
  }

  initForm(): void {
    this.fg = this.fb.group({
      name: ['', [Validators.required]],
      valid: [true],
      completePeriodicCheck: [false],
    });
  }

  populateForm(): void {
    if (!this.occupation) return;

    this.fg.patchValue({
      name: this.occupation.name,
      valid: this.occupation.valid,
      completePeriodicCheck: this.occupation.allRiskFactorExam,
    });
  }

  update(): void {
    if(this.fg.invalid) {
      this.fg.markAllAsTouched();
      return;
    }
    const updateDto: OccupationUpdateApiModel = {
      id: this.occupation?.id!,
      name: this.fg.value.name,
      valid: this.fg.value.valid,
      allRiskFactorExam: this.fg.value.completePeriodicCheck,
    };
    this.occupationService.occupationUpdate(updateDto).subscribe(
      () => {},
      (error) => {
          console.error('Nastala chyba v komunikácii so serverom');
      }
    );
  }

}
