import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CategorizationProviderService,
  EmployerService,
  OccupationCategorizationApiModel,
  OccupationCategorizationNewApiModel,
  OccupationCategorizationService,
  OccupationCategorizationUpdateApiModel,
} from 'src/api-new';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { TableLoaderService } from 'src/app/services/table-loader.service';
import {calculateNextFreeDate, calculateNextFreeDateWithoutEndDate} from "../../../../utils";

interface FormField {
  formControlName: string;
  label: string;
  type: 'text' | 'date' | 'select';
  options$?: Observable<Array<{ label: string; value: number }>>;
  validators: Validators[];
  errorMessage: string;
}

interface CategorizationForm {
  employerId: FormControl<number>;
  number: FormControl<string>;
  validSince: FormControl<Date | null>;
  validUntil: FormControl<Date | null>;
}

@Component({
  selector: 'app-add-edit-categorization-modal',
  templateUrl: './add-edit-categorization-modal.component.html',
  styleUrls: ['./add-edit-categorization-modal.component.scss'],
})
export class AddEditCategorizationModalComponent implements OnInit, OnDestroy {
  fg: FormGroup<CategorizationForm>;
  fields: FormField[];
  editMode = false;
  categorization: OccupationCategorizationApiModel;
  selectEmployer$: Observable<Array<{ label: string; value: number }>>;
  subs: Subscription = new Subscription();
  existingCategorizations: OccupationCategorizationApiModel[] = [];

  constructor(
    private ref: NbDialogRef<AddEditCategorizationModalComponent>,
    private categorizationProviderService: CategorizationProviderService,
    private employerService: EmployerService,
    private occupationCategorizationService: OccupationCategorizationService,
    private nbToastr: NbToastrService,
    private tableLoader: TableLoaderService
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.setupFields();
    this.loadExistingCategorizations();
    if(this.categorization) {
        this.editMode = true;
        this.patchForm();
    }
  }

  private initializeForm(): void {
    this.fg = new FormGroup<CategorizationForm>(<CategorizationForm>{
      employerId: new FormControl<number | null>(null, Validators.required),
      number: new FormControl<string>('', Validators.required),
      validSince: new FormControl<Date | null>(null, Validators.required),
      validUntil: new FormControl<Date | null>(null),
    });
    this.fg.get('number')?.valueChanges.subscribe((name) => this.validateName(name));
    this.fg.get('validSince')?.valueChanges.subscribe(() => this.validateDateRange());
    this.fg.get('validUntil')?.valueChanges.subscribe(() => this.validateDateRange());
  }

  private setupFields(): void {
    this.selectEmployer$ = this.employerService.employerGetAllEmployers().pipe(
      map((providers) =>
        providers.map((provider) => ({
          label: provider.name || '',
          value: provider.id,
        }))
      )
    );

    this.fields = [
      {
        formControlName: 'employerId',
        label: 'Zamestnávateľ',
        type: 'select',
        options$: this.selectEmployer$,
        validators: [Validators.required],
        errorMessage: 'Vyžaduje sa zamestnávateľ.',
      },
      {
        formControlName: 'number',
        label: 'Číslo kategorizácie',
        type: 'text',
        validators: [Validators.required],
        errorMessage: 'Číslo kategorizácie je povinné.',
      },
      {
        formControlName: 'validSince',
        label: 'Platná od',
        type: 'date',
        validators: [Validators.required],
        errorMessage: 'Platná od je povinná.',
      },
      {
        formControlName: 'validUntil',
        label: 'Platná do',
        type: 'date',
        validators: [],
        errorMessage: '',
      },
    ];
  }

  private patchForm(): void {
    this.fg.patchValue({
      employerId: this.categorization.employerId,
      number: this.categorization.categorizationName,
      validSince: this.categorization.validSince
        ? new Date(this.categorization.validSince)
        : null,
      validUntil: this.categorization.validUntil
        ? new Date(this.categorization.validUntil)
        : null,
    });
  }

  save(): void {

    this.validateDateRange();

    if (this.fg.invalid) {
      this.fg.markAllAsTouched();
      return;
    }

    this.tableLoader.set(true);
    const formValue = this.fg.value;
    const normalizeToUTC = (date: Date | null): string | null => {
      if (!date) return null;
      const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      return utcDate.toISOString();
    };

    if (this.editMode) {
      const updateModel: OccupationCategorizationUpdateApiModel = {
        id: this.categorization?.id!,
        categorizationName: formValue.number!,
        validSince: normalizeToUTC(formValue.validSince!)!,
        validUntil: normalizeToUTC(formValue.validUntil!)!,
      };

      this.subs.add(
        this.occupationCategorizationService
          .occupationCategorizationUpdateCategorization(updateModel)
          .subscribe(
            () => this.ref.close(updateModel),
            (err) => {
              this.tableLoader.set(false);
              this.nbToastr.danger('Chyba v komunikácii so serverom', err.status);
            }
          )
      );
    } else {
      const createModel: OccupationCategorizationNewApiModel = {
        employerId: formValue.employerId!,
        categorizationName: formValue.number!,
        validSince: normalizeToUTC(formValue.validSince!)!,
        validUntil: normalizeToUTC(formValue.validUntil!)!,
      };

      this.subs.add(
        this.occupationCategorizationService
          .occupationCategorizationCreateCategorization(createModel)
          .subscribe(
            () => this.ref.close(true),
            (err) => {
              this.tableLoader.set(false);
              this.nbToastr.danger('Chyba v komunikácii so serverom', err.status);
            }
          )
      );
    }
  }

  close(): void {
    this.ref.close(false);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private loadExistingCategorizations(): void {
    this.subs.add(
      this.occupationCategorizationService.occupationCategorizationGetAll().subscribe(
        (data) => {
          this.existingCategorizations = data;
          console.log(data,' data');
        },
        (err) => {
          this.nbToastr.danger('Nepodarilo sa načítať existujúce kategorizácie.', 'Chyba');
        }
      )
    );
  }

  private validateName(name: string): void {
    if (!name) {

      return;
    }

    const nameExists = this.existingCategorizations.some(
      (category) =>
        category.categorizationName.toLowerCase() === name.toLowerCase() &&
        (!this.editMode || category.id !== this.categorization?.id)
    );

    if (nameExists) {
      this.fg.get('number')?.setErrors({ nameExists: true });
    } else {

    }
  }

  private validateDateRange(): void {
    const validSince = this.fg.get('validSince')?.value;
    const validUntil = this.fg.get('validUntil')?.value;


    this.fg.get('validUntil')?.setErrors(null);

    if (!validSince) return;

    const startDate = new Date(validSince);
    const endDate = validUntil ? new Date(validUntil) : null;

    if (endDate && startDate > endDate) {
      this.fg.get('validSince')?.setErrors({ invalidRange: true });
      this.fg.get('validUntil')?.setErrors({ invalidRange: true });
      return;
    }

    const sortedCategorizations = [...this.existingCategorizations].sort((a, b) =>
      new Date(a.validSince).getTime() - new Date(b.validSince).getTime()
    );

    let overlap = false;
    let conflictingCategorization: OccupationCategorizationApiModel | null = null;
    let nextFreeStartDate: Date | null = null;

    let infiniteCategorization = sortedCategorizations.find(item => item.validUntil === null);
    if (infiniteCategorization?.id === this.categorization?.id) {
      infiniteCategorization = undefined;
    }

    const infinityOccupationStartDate = infiniteCategorization
      ? new Date(infiniteCategorization.validSince)
      : null;

    for (let i = 0; i < sortedCategorizations.length; i++) {
      const categorization = sortedCategorizations[i];

      if (this.editMode && categorization.id === this.categorization?.id) {
        continue;
      }

      const catStart = new Date(categorization.validSince);
      const catEnd = categorization.validUntil ? new Date(categorization.validUntil) : null;

      if (!endDate && infiniteCategorization && infinityOccupationStartDate) {
        overlap = true;
        conflictingCategorization = infiniteCategorization;
        break;
      }

      if (!endDate && catEnd && startDate <= catEnd) {
        nextFreeStartDate = endDate == null ? null : calculateNextFreeDateWithoutEndDate(startDate, sortedCategorizations);
        overlap = true;
        conflictingCategorization = categorization;
        break;
      }

      if (endDate) {
        if (infiniteCategorization && infinityOccupationStartDate && infinityOccupationStartDate <= startDate) {
          overlap = true;
          conflictingCategorization = infiniteCategorization;
          nextFreeStartDate = null;
          break;
        }

        if (catEnd && startDate <= catEnd && endDate >= catStart) {
          overlap = true;
          conflictingCategorization = categorization;
          if (catEnd) {
            nextFreeStartDate = calculateNextFreeDate(catEnd, sortedCategorizations, i);
          }
          break;
        }

        if (infiniteCategorization && infinityOccupationStartDate && endDate >= infinityOccupationStartDate) {
          overlap = true;
          conflictingCategorization = infiniteCategorization;
          nextFreeStartDate = null;
          break;
        }
      }
    }

    if (overlap) {

      const sinceDate = new Date(conflictingCategorization!.validSince).toLocaleDateString();
      const untilPart = conflictingCategorization?.validUntil
        ? `do ${new Date(conflictingCategorization.validUntil).toLocaleDateString()}`
        : 'do nekonečna';

      const conflictMessage = `Vybrané dátumy sa prekrývajú s existujúcou kategorizáciou: ${
        conflictingCategorization?.categorizationName
      } s intervalom ${sinceDate} ${untilPart}.`;


      this.fg.get('validSince')?.setErrors({
        overlap: true,
        conflictingCategorization: {
          categorizationName: conflictingCategorization?.categorizationName,
          validSince: conflictingCategorization?.validSince,
          validUntil: conflictingCategorization?.validUntil
        },
        nextFreeStartDate,
        conflictMessage,
      });
      if(validSince) {
        this.fg.get('validUntil')?.setErrors({
          overlap: true,
          conflictingCategorization: {
            categorizationName: conflictingCategorization?.categorizationName,
            validSince: conflictingCategorization?.validSince,
            validUntil: conflictingCategorization?.validUntil,
          },
          nextFreeStartDate,
          conflictMessage,
        });
      }

    } else {

      this.fg.get('validSince')?.setErrors(null);
      this.fg.get('validUntil')?.setErrors(null);
    }
  }

}
