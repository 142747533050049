<form [formGroup]="fg">
  <mat-form-field appearance="fill">
    <mat-label>Názov profesie</mat-label>
    <input matInput formControlName="name" placeholder="Názov profesie">
    <mat-error *ngIf="fg.get('name')?.invalid">Názov profesie je povinný</mat-error>
  </mat-form-field>
  <div class="mat-slide-group">
    <div class="row">
      <nb-toggle formControlName="valid" [checked]="fg.get('valid')?.value" style="scale: 1.6;"></nb-toggle>
      <span class="row-title">Aktivna profesia</span>
    </div>
    <span>
        Sprístupniť profesiu pri vytvorení nového kontraktu zamestnanca.
      </span>
    <div class="row">
      <nb-toggle formControlName="completePeriodicCheck" [checked]="fg.get('completePeriodicCheck')?.value" style="scale: 1.6;"></nb-toggle>
      <span class="row-title">Periodické plánovanie faktorov</span>
    </div>
    <span>
         V prídpade expirácie akéhokoľvek faktoru bude objednávka obsahovať všetky položky.
      </span>
  </div>
</form>
