/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EmployeeGridListApiModel { 
    id: number;
    name: string;
    surname: string;
    dateOfBirth: string;
    personalNumber: string;
    active: boolean;
    hasActiveContract: boolean;
    isOrdered: boolean;
    eligibleTo?: string;
    occupations: Array<string>;
}

