import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  NbThemeModule,
  NbLayoutModule,
  NbSidebarModule,
  NbMenuModule,
  NbDialogModule,
  NbDatepickerModule,
  NbTimepickerModule,
  NbToastrModule,
  NbCardModule,
  NbIconLibraries
} from '@nebular/theme';
import {NbDateFnsDateModule} from '@nebular/date-fns';
import {NbEvaIconsModule} from '@nebular/eva-icons';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import '@angular/common/locales/global/sk';
import {ApiModule, BASE_PATH} from '../api-new';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import {LottieModule} from 'ngx-lottie';
import player from 'lottie-web/build/player/lottie_svg';
import {FilterInterceptor} from './interceptors/filter.interceptor';
import {getBasePath} from '../config';
import {SharedModule} from './modules/shared/shared.module';
import {CustomIconsService} from './services/custom-icons.service';
import {customIconsInitializeFactory} from './utils';
import {EmployerFilterService} from "./services/employer-filter.service";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";
import {MatNativeDateModule} from "@angular/material/core";
import {Overlay} from "@angular/cdk/overlay";
import {MAT_DATEPICKER_SCROLL_STRATEGY} from "@angular/material/datepicker";
import {MatInputModule} from "@angular/material/input";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatSelectModule} from "@angular/material/select";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {MatDialogModule} from "@angular/material/dialog";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";



export function playerFactory() { // Loading could be done on demand, if performance is affected
  return player;
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({name: 'corporate'}),
    NbLayoutModule,
    NbEvaIconsModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDialogModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbTimepickerModule.forRoot(),
    NbToastrModule.forRoot(),
    NbDateFnsDateModule.forRoot({format: 'dd.MM.yyyy'}),
    ApiModule,
    HttpClientModule,
    LottieModule.forRoot({player: playerFactory}),
    NbCardModule,
    SharedModule,
    MatNativeDateModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule],
  providers: [
    {
      provide: BASE_PATH,
      useValue: getBasePath(),
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [NbIconLibraries, CustomIconsService],
      useFactory: customIconsInitializeFactory
    },
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'fill'}},
    {
      provide: MAT_DATEPICKER_SCROLL_STRATEGY,
      useFactory: (overlay: Overlay) => () => overlay.scrollStrategies.block(),
      deps: [Overlay]
    },
    {provide: LOCALE_ID, useValue: 'sk'},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: FilterInterceptor, multi: true},
    provideHttpClient(withInterceptorsFromDi())
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/i18n/lang_', '.json');
}
