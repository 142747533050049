import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import {ProviderApiModel, ProviderService} from "../../api-new";
import { AuthService, UserRole } from "./auth.service";
import { filter } from "rxjs/operators";
import { UserDetailService } from './user-detail.service';

@Injectable({
  providedIn: 'root'
})
export class ProvidersFilterService {
  protected selectedEmployerId: number | null;
  public selectedProvider$ = new BehaviorSubject<ProviderApiModel | null>(null);
  public loadedProviders$ = new BehaviorSubject<Array<ProviderApiModel> | null>(null);

  constructor(
    authService: AuthService,
    private providerService: ProviderService,
    private userDetailService: UserDetailService
  ) {
    authService.loggedIn.pipe(filter(loggedIn => !loggedIn)).subscribe(() => {
      this.reset()
    })
  }

  public reload() {
    if (this.selectedEmployerId) {
      this.providerService.providerGetActiveEmployerProviders(this.selectedEmployerId).toPromise().then(providers => {
        this.loadedProviders$.next(providers);
        if (this.userDetailService.role === UserRole.health_care_manager) {
          this.changeProvider(providers[0].id);
        }
      });
    } else {
      this.loadedProviders$.next(null);
    }
  }

  public reset() {
    this.selectedProvider$.next(null);
    this.loadedProviders$.next(null);
  }


  public getSelectedProvider() {
    return this.selectedProvider$.getValue();
  }

  public setSelectedEmployer(employerId: number | null) {
    this.selectedEmployerId = employerId;
    if (!employerId) {
      this.reset();
    }
  }

  public getLoadedProviders() {
    return this.loadedProviders$.getValue();
  }

  changeProvider(providerId: number) {
    let provider = this.getLoadedProviders()?.find(provider => provider.id === providerId)
    if (provider != null) {
      this.selectedProvider$.next(provider);
    }
  }
}
